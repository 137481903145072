import headerList from "@/components/common/header-list.vue";
import store from "@/store/index.js";
import paginationMixin from "@/Mixins/paginationMixin";
import billingInfo from "../../manage/billing-info.vue";
import { EventBus } from "@/event-bus";

export default {
  data: () => ({
    salesRefList: [],
    error: "",
    searchText: "",
    showLoader: false,
    teamList: [],
    usersList: [],
    opened: [],
    number: 0,
    expandedRow: null,
    totalOpen: 0,
    totalReceived: 0,
    totalProcessing: 0,
    totalTicketed: 0,
    totalAccounting: 0,
    totalClosed: 0,
    sale_ref: {},
    isTableExpanded1: true,
    isTableExpanded2: true,
    isTableExpanded3: false,
    isTableExpanded4: false,
    isTableExpanded5: false,
    isTableExpanded6: false,
    queueOrder: ["Open", "Received", "Ticketed", "Closed"],
    // openSales: [],
    grossProfitCount: {},
    all_sales: localStorage.getItem("all_sales") === "true" || false,
    callQueuePresence:
      parseInt(localStorage.getItem("call_queue_presence")) || 0,
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, "billing-info": billingInfo },
  watch: {
    all_sales(newValue, oldVal) {
      localStorage.setItem("all_sales", newValue);
      if (newValue !== oldVal) {
        this.totalOpen = 0;
        this.totalReceived = 0;
        this.totalTicketed = 0;
        this.totalClosed = 0;
      }
      this.getAll();
    },
  },
  computed: {
    isToggleSwitchPermission() {
      const permission = this.hasToggleSwitchPermission();
      return permission;
    },
    issalesRecoverPermission() {
      const permission = this.hassalesRecoverPermission();
      return permission;
    },
    isSalespermanentDeletePermission() {
      const permission = this.hasSalespermanentDeletePermission();
      return permission;
    },
    hasOpenSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Open" && this.totalOpen != 0
      );
    },
    hasReceivedSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Received" && this.totalReceived != 0
      );
    },
    hasTicketedSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Ticketed" && this.totalTicketed != 0
      );
    },
    hasClosedSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Closed" && this.totalClosed != 0
      );
    },
    user() {
      return this.$store.state.userDetail;
    },
  },
  methods: {
    updateQueuePresence() {
      const newValue = this.callQueuePresence === 1 ? 0 : 1;
      this.axios
        .get("/update-queue-presence", {
          params: {
            presence: newValue,
          },
        })
        .then((response) => {
          this.callQueuePresence = newValue;
          localStorage.setItem("call_queue_presence", newValue);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    hassalesRecoverPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "recover-deleted-sale";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSalespermanentDeletePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "permanent-delete-sale";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasToggleSwitchPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-view-all-sales";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    toggleTable(tableNumber) {
      if (tableNumber === 0) {
        this.isTableExpanded1 = !this.isTableExpanded1;
      } else if (tableNumber === 1) {
        this.isTableExpanded2 = !this.isTableExpanded2;
      } else if (tableNumber === 3) {
        this.isTableExpanded4 = !this.isTableExpanded4;
      } else if (tableNumber === 5) {
        this.isTableExpanded6 = !this.isTableExpanded6;
      }
    },
    toggleOpen(index) {
      if (this.expandedRow === index) {
        this.expandedRow = null;
      } else {
        this.expandedRow = index;
      }
    },
    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },
    // start sales details
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [id];
      }
    },
    // end sales details
    search() {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.$store.state.recordTake;
      queryString +=
        this.searchText.length > 0
          ? "&search=" + this.searchText
          : "" + "&all_sales=" + this.all_sales;
      return queryString;
    },
    getTeam() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () { });
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () { });
    },
    getAll(isShowLoader = true) {
      let _vm = this;
      let queryString = this.getQueryString();
      if (isShowLoader) {
        _vm.showLoader = true;
      }
      this.axios
        .get("/sales-deleted")
        .then(function (response) {
          _vm.showLoader = false;
          _vm.salesRefList = response.data.data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total_record,
            _vm.$store.state.recordTake
          );
        })
        .catch(function (error) { });
    },
    recoverSales(sale_reference_id) {
      let _vm = this;
      let _msg =
        `Are you sure you want to Recover this Sale? <br>` + sale_reference_id;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          store.state.isLoaderShow = true;
          _vm.axios
            .get("/sales-recover/" + sale_reference_id)
            .then(function (response) {
              _vm.getAll();
              store.state.isLoaderShow = false;
            });
        })
        .catch(function () { });
    },
    deleteSales(sale_reference_id) {
      let _vm = this;
      let _msg =
        `Are you sure you want to Permanent Delete this Sale? ` +
        sale_reference_id;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          store.state.isLoaderShow = true;
          _vm.axios
            .get("/sales-permanent-delete/" + sale_reference_id)
            .then(function (response) {
              _vm.getAll();
              store.state.isLoaderShow = false;
            });
        })
        .catch(function () { });
    },
  },
  mounted() {
    this.getAll();
    this.getTeam();
    this.getUserList();
    this.callQueuePresence =
      parseInt(localStorage.getItem("call_queue_presence")) || 0;
  },
  created() {
    EventBus.$on("NewUserStatus", (notification) => {
      this.callQueuePresence = notification.data.status;
      localStorage.setItem("call_queue_presence", notification.data.status);
    });
  },
  beforeDestroy() {
    EventBus.$off("NewUserStatus");
  },
};
